<template>
    <div class="container" style="padding-bottom: 20px;">
        <TopRow :key="componentKey" ref="forceRerender"/>
        <div class="row" style="margin-bottom: 15px">
            <router-view />
        </div>
    </div>
</template>

<script>
import TopRow from "./components/TopRow.vue"
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

console.log(`VUE_APP_ROOT_URL: ${process.env.VUE_APP_ROOT_URL}`);
console.log(`BASE_URL: ${process.env.BASE_URL}`);


async function buildAuth() {
    return cookies.get('theAuthCookie')
}


export default {
    components: { TopRow, },
    data() {
        return {
            outputFormat: this.$route.query.of,
            componentKey: 0,
            theAuthCookie: buildAuth(),
        }
    },
    async mounted() {
        //setupAuthInfo(this.theAuthCookie)
        if (this.outputFormat == 1) {
            console.log("app outputFormat 1")
        }
    },
    methods: {
        forceRerender() {
            //setupAuthInfo(this.theAuthCookie)
            this.componentKey += 1;
        }
    }
}
</script>

<style>
body {
    background: #b0b9bb;
    margin: 8px;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #b0b9bb;
}
</style>
